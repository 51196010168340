<script setup>
import { ref } from 'vue';
import {Head, Link, router} from '@inertiajs/vue3';
import Logo from '@/logo.png'
import {useDisplay} from "vuetify";

const { mobile } = useDisplay();
const isDrawerOpen = ref(!mobile.value);

const menuItems = [
    /*{
        route: 'dashboard',
        label: 'Tableau de bord',
        icon: 'mdi-view-dashboard'
    },*/
    {
        header: 'Evénements',
        route: 'events.index',
        label: 'Liste',
        icon: 'mdi-calendar'
    },
    {
        header: 'Utilisateurs',
        route: 'users',
        label: 'Liste',
        icon: 'mdi-account-multiple'
    },
    {
        route: 'user-invitations.index',
        label: 'Invitations',
        icon: 'mdi-account-plus'
    },
]

const logout = () => {
    router.post(route('logout'));
};
</script>

<template>
    <Head>
        <meta name="robots" content="noindex" />
    </Head>
    <VApp>
        <VNavigationDrawer
        v-model="isDrawerOpen"
        class="bg-blue-grey-lighten-5"
        floating
        >
            <VList>
                <VListItem class="w-75 mx-auto">
                <VImg :src="Logo" />
                </VListItem>
            </VList>

            <VList density="comfortable" class="pr-4">
                <template
                    v-for="menuItem in menuItems"
                    :key="menuItem.route">
                    <VListSubheader v-if="menuItem.header">{{menuItem.header}}</VListSubheader>
                    <Link

                    :href="route(menuItem.route)"
                    class="link-inherit">
                        <VHover
                        v-slot:default="{ isHovering, props }">
                            <VListItem
                            v-bind="props"
                            link
                            class="rounded-e mb-2"
                            :class="{'bg-primary': route().current(menuItem.route), 'bg-grey-lighten-3': isHovering}"
                            :prepend-icon="menuItem.icon"
                            :title="menuItem.label" />
                        </VHover>
                    </Link>
                </template>
            </VList>
    </VNavigationDrawer>
    <VAppBar class="bg-blue-grey-lighten-5" elevation="0">
        <template v-slot:prepend>
          <VAppBarNavIcon  @click="isDrawerOpen = !isDrawerOpen"/>
        </template>
        <VMenu>
            <template v-slot:activator="{ props }">
                <VBtn
                    v-bind="props"
                    icon
                    color="primary">
                    <VAvatar
                        v-if="$page.props.auth.user.profile_photo_url"
                        :image="$page.props.auth.user.profile_photo_url"
                        />
                    <VAvatar v-else>
                        <VIcon
                        icon="mdi-account-circle"
                        color="primary"
                        size="x-large" />
                    </VAvatar>
                </VBtn>

            </template>
            <VList nav>
                <VListItem
                :title="$page.props.auth.user.name"
                :subtitle="$page.props.auth.user.email"
                class="text-center">
                </VListItem>
                <VDivider />
                <Link
                :href="route('profile.show')"
                class="link-inherit">
                    <VHover
                    v-slot:default="{ isHovering, props }">
                        <VListItem
                        v-bind="props"
                        link
                        :class="{'bg-grey-lighten-3': isHovering}"
                        prepend-icon="mdi-account-outline"
                        title="Profil" />
                    </VHover>
                </Link>
                <Link
                    :href="route('organizations.index')"
                    class="link-inherit">
                    <VHover
                        v-slot:default="{ isHovering, props }">
                        <VListItem
                            v-bind="props"
                            link
                            :class="{'bg-grey-lighten-3': isHovering}"
                            prepend-icon="mdi-home"
                            :title="$t('organization.my_organizations')" />
                    </VHover>
                </Link>
                <VHover
                v-slot:default="{ isHovering, props }">
                    <VListItem
                        v-bind="props"
                        link
                        prepend-icon="mdi-logout"
                        title="Déconnexion"
                        :class="{'bg-grey-lighten-3': isHovering}"
                        @click="logout" />
                </VHover>
            </VList>
        </VMenu>
    </VAppBar>
    <VMain class="bg-blue-grey-lighten-5">
        <VRow no-gutters class="justify-center">
            <VCol cols="12" xl="10" xxl="8">
                <slot />
            </VCol>
        </VRow>
    </VMain>
  </VApp>
</template>
