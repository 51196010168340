import { createVuetify } from 'vuetify'
import { md2 } from 'vuetify/blueprints'
import '../../css/vuetify.scss'
import '@mdi/font/css/materialdesignicons.css'
import LuxonAdapter from "@date-io/luxon";
import {fr} from 'vuetify/locale'

export default createVuetify({
    locale: {
      locale: 'fr',
      fallback: 'fr',
      messages: {fr}
    },
    date: {
        adapter: new LuxonAdapter({locale: 'fr'})
    },
    blueprint: md2
})
